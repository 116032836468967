import React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';

interface Props {
	className?: string;
	url: string;
	anchorState?: any;
	cursorClass?: string;
	children: React.ReactNode;
}

function Anchor({ children, url, anchorState, className, cursorClass }: Props) {
	const handleMouseEnter = () => {
		const cursor = document.getElementById('cursor');
		cursor?.classList.add('cursor--button');
		if (cursorClass !== undefined) {
			cursor?.classList.add(`cursor--${cursorClass}`);
		}
	};

	const handleMouseLeave = () => {
		const cursor = document.getElementById('cursor');
		cursor?.classList.remove('cursor--button');
		if (cursorClass !== undefined) {
			cursor?.classList.remove(`cursor--${cursorClass}`);
		}
	};

	return (
		<TransitionLink
			className={className}
			to={url}
			exit={{
				length: 0.7,
				state: {},
			}}
			entry={{
				delay: 0.7,
				state: anchorState,
			}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{children}
		</TransitionLink>
	);
}

export default Anchor;

import React, { useEffect, useState } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import { useLocation } from '@reach/router';
import Anchor from '../../components/ui/Anchor';

function Logo() {
	const { scroll } = useLocomotiveScroll();
	const location = useLocation();
	const [cursor, setCursor] = useState<HTMLElement | null>(null);

	useEffect(() => {
		setCursor(document.getElementById('cursor'));
	}, []);

	const handleButtonMouseEnter = () => {
		cursor?.classList.add('cursor--button');
	};

	const handleButtonMouseLeave = () => {
		cursor?.classList.remove('cursor--button');
	};

	const handleScrollHome = () => {
		const heroSection = document.querySelector('#hero');

		if (heroSection === null) {
			return;
		}

		scroll.scrollTo(heroSection);
	};

	return (
		<>
			{location.pathname === '/' || location.pathname === '/roderick-cox/' ? (
				<button
					role="button"
					className="site-logo"
					onClick={handleScrollHome}
					onMouseEnter={handleButtonMouseEnter}
					onMouseLeave={handleButtonMouseLeave}
				>
					Roderick Cox
				</button>
			) : (
				<Anchor
					className="site-logo"
					url="/"
				>
					Roderick Cox
				</Anchor>
			)}
		</>
	);
}

export default Logo;

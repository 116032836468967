import React from 'react';
import Logo from './Logo';

function Header() {

	return (
		<>
			<header className="site-header" />
			<Logo />
		</>
	);
}

export default Header;

import React, { useState, useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import { useLocation } from '@reach/router';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import IconButton from '../../components/ui/IconButton';
import Anchor from '../../components/ui/Anchor';

function Nav() {
	const { scroll } = useLocomotiveScroll();
	const [navOpen, setNavOpen] = useState(false);
	const [cursor, setCursor] = useState<HTMLElement | null>(null);
	const location = useLocation();

	useEffect(() => {
		setCursor(document.getElementById('cursor'));
	}, []);

	useEffect(() => {
		if (navOpen) {
			document.body.classList.add('menu-open');
		} else {
			document.body.classList.remove('menu-open');
			cursor?.classList.remove('cursor--button', 'cursor--icon-cross');
		}
	}, [navOpen]);

	const navigateHandler = (target: string) => {
		const nextSection = document.querySelector(target);

		if (nextSection === null) {
			return;
		}

		scroll.scrollTo(nextSection);
		
		setTimeout(() => {
			setNavOpen(false);
		}, 250);
	};

	const handleNavMouseEnter = () => {
		document.body.classList.add('cursor-white');
	};

	const handleNavMouseLeave = () => {
		document.body.classList.remove('cursor-white');
	};

	const handleButtonMouseEnter = () => {
		if (navOpen) {
			document.body.classList.add('cursor-white');
		}
		cursor?.classList.add('cursor--button');
	};

	const handleButtonMouseLeave = () => {
		cursor?.classList.remove('cursor--button');
	};

	const handleOverlayMouseEnter = () => {
		if (navOpen) {
			document.body.classList.add('cursor-white');
		}
		cursor?.classList.add('cursor--button', 'cursor--icon-cross');
	};

	const handleOverlayMouseLeave = () => {
		cursor?.classList.remove('cursor--button', 'cursor--icon-cross');
	};

	return (
		<>
			{navOpen && (
				<div
					className="nav-overlay"
					onClick={() => setNavOpen(false)}
					onMouseEnter={handleOverlayMouseEnter}
					onMouseLeave={handleOverlayMouseLeave}
				/>
			)}
			<nav
				className="site-nav"
				onMouseEnter={handleNavMouseEnter}
				onMouseLeave={handleNavMouseLeave}
			>
				<ul className="site-nav__menu">
					<li>
						<Anchor className="site-nav__button" url="/schedule">Schedule</Anchor>
					</li>
					<li>
						<Anchor className="site-nav__button" url="/about">About</Anchor>
					</li>
					<li>
						{location.pathname === '/' || location.pathname === '/roderick-cox/' ? (
							<button
								role="button"
								className="site-nav__button"
								onClick={() => navigateHandler('#media')}
								onMouseEnter={handleButtonMouseEnter}
								onMouseLeave={handleButtonMouseLeave}
							>
								Media
							</button>
						) : (
							<Anchor
								className="site-nav__button"
								url="/"
								anchorState={{ anchorTo: '#media' }}
							>
								Media
							</Anchor>
						)}
					</li>
					<li>
						<Anchor className="site-nav__button" url="/rcmi">RCMI</Anchor>
					</li>
					<li>
						{location.pathname === '/' || location.pathname === '/roderick-cox/' ? (
							<button
								role="button"
								className="site-nav__button"
								onClick={() => navigateHandler('#contact')}
								onMouseEnter={handleButtonMouseEnter}
								onMouseLeave={handleButtonMouseLeave}
							>
								Connect
							</button>
						) : (
							<Anchor
								className="site-nav__button"
								url="/"
								anchorState={{ anchorTo: '#contact' }}
							>
								Connect
							</Anchor>
						)}
					</li>
				</ul>
				<ul className="site-nav__social">
					<li>
						<IconButton
							className="icon-button--sm"
							url="https://www.facebook.com/roderickcoxconductor/"
							opensNewTab
						>
							<FaFacebookF />
						</IconButton>
					</li>
					<li>
						<IconButton
							className="icon-button--sm"
							url="https://www.instagram.com/roderickcoxconductor/"
							opensNewTab
						>
							<FaInstagram />
						</IconButton>
					</li>
					<span>&nbsp;</span>
				</ul>
			</nav>
			<button
				className="menu-button"
				onClick={() => setNavOpen((prev) => !prev)}
				onMouseEnter={handleButtonMouseEnter}
				onMouseLeave={handleButtonMouseLeave}
			>
				<span className="lines"></span>
			</button>
		</>
	);
}

export default Nav;

import { useEffect, useState } from 'react';

function useCursorPosition() {
	const [position, setPosition] = useState({ x: 0, y: 0 });

	useEffect(() => {
		const setFromEvent = (e: any) => {
			setPosition({ x: e.clientX, y: e.clientY });
			const ePath = e.path || (e.composedPath && e.composedPath());

			if (ePath === undefined) {
				return;
			}

			ePath.map((x: any) => {
				if (x.tagName !== 'SECTION') {
					return;
				}

				if (x.getAttribute('data-bg-colour') === 'brand-one') {
					document.body.classList.add('cursor-white');
				} else {
					document.body.classList.remove('cursor-white');
				}
			});
		};
		window.addEventListener('mousemove', setFromEvent);

		return () => {
			window.removeEventListener('mousemove', setFromEvent);
		};
	}, []);

	return position;
}

export default useCursorPosition;

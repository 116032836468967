import React, { useEffect, useState } from 'react';

interface Props {
	className?: string;
	url?: string;
	cursorClass?: string;
	opensNewTab?: boolean;
	onClick?: () => void;
	children: React.ReactNode;
}

function IconButton({ children, url, className, cursorClass, opensNewTab = false, onClick }: Props) {
	const [classNames, setClassNames] = useState('');

	useEffect(() => {
		let classes = 'icon-button';

		if (className !== undefined) {
			classes = `${classes} ${className}`;
		}

		if (cursorClass !== undefined) {
			classes = `${classes} button--no-cursor`;
		}

		setClassNames(classes);
	}, []);

	const handleMouseEnter = () => {
		const cursor = document.getElementById('cursor');
		cursor?.classList.add('cursor--button');
		if (cursorClass !== undefined) {
			cursor?.classList.add(`cursor--${cursorClass}`);
		}
	};

	const handleMouseLeave = () => {
		const cursor = document.getElementById('cursor');
		cursor?.classList.remove('cursor--button');
		if (cursorClass !== undefined) {
			cursor?.classList.remove(`cursor--${cursorClass}`);
		}
	};

	return (
		<a
			className={classNames}
			href={url}
			target={opensNewTab ? '_blank' : '_self'}
			onClick={onClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{children}
		</a>
	);
}

export default IconButton;

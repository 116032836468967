import React from 'react';
import useCursorPosition from '../../hooks/useCursorPosition';

function Cursor() {
	const { x, y } = useCursorPosition();

	return (
		<span className="cursor" id="cursor">
			<span className="cursor__tail" style={{ '--pos-x': `${x}px`, '--pos-y': `${y}px` } as React.CSSProperties} />
			<span className="cursor__tail cursor-tail--end" style={{ '--pos-x': `${x}px`, '--pos-y': `${y}px` } as React.CSSProperties} />
		</span>
	);
}

export default Cursor;
